import { Link } from "react-router-dom";
import '../App.css';

function VideoData() {
  return (
    <div className="App">
      <header className="App-header">
        <nav
          style={{
            borderBottom: "solid 1px",
            paddingBottom: "1rem",
          }}
        >
          <Link to="/">Home</Link> |{" "}
          <Link to="/test">Test Home</Link>
        </nav>
      </header>
    </div>
  );
}

export default VideoData;
