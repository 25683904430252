import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import App from './App';
import NavTest from './pages/NavTest'
import VideoData from './pages/VideoData'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<App/>}/>
        <Route path={"/test"} element={<NavTest/>}/>
        <Route path={"/test/monkeys"} element={<VideoData subject={'monkeys'}/>}/>
        <Route path={"/test/roads"} element={<VideoData subject={'roads'}/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
